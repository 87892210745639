import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52355d7d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-mb-2 p-error f-size-14" }
const _hoisted_3 = { class: "p-mt-2 p-p-3 shadow bg-color-secondary rounded p-d-flex" }
const _hoisted_4 = { class: "p-d-flex" }
const _hoisted_5 = { class: "p-my-auto" }
const _hoisted_6 = { class: "p-my-auto p-ml-2 product-image-wrap" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "p-ml-2 w-100" }
const _hoisted_9 = { class: "p-d-flex" }
const _hoisted_10 = { class: "line-clamp w-100" }
const _hoisted_11 = { class: "p-ml-auto" }
const _hoisted_12 = {
  key: 0,
  class: "f-size-14 text-gray-1"
}
const _hoisted_13 = { class: "p-d-flex p-mt-4" }
const _hoisted_14 = { class: "f-size-14 text-gray-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.selectedPromotionDisplay)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Dialog, {
          header: 'โปรโมชั่น ' + _ctx.selectedPromotionDisplay.promotionName,
          visible: _ctx.displayModal,
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayModal) = $event)),
          style: { width: '95vw', 'max-width': ' 600px' },
          modal: true
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "ยกเลิก",
              class: "p-button-text",
              style: _normalizeStyle({
            color: _ctx.backgroundColor,
          }),
              onClick: _ctx.closeModal
            }, null, 8, ["style", "onClick"]),
            _createVNode(_component_Button, {
              label: "ตกลง",
              autofocus: "",
              onClick: _ctx.onSubmitFreeProduct,
              style: _normalizeStyle({
            'background-color': _ctx.backgroundColor,
            'border-color': _ctx.backgroundColor,
          })
            }, null, 8, ["onClick", "style"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.validateMessage), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedPromotionDisplay.products, (freeProduct, fpindex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'freeproduct_' + fpindex
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_Checkbox, {
                          id: 'freeProductselected-' + freeProduct.id,
                          modelValue: freeProduct.selected,
                          "onUpdate:modelValue": ($event: any) => ((freeProduct.selected) = $event),
                          binary: true,
                          disabled: freeProduct.isApply,
                          onChange: _ctx.onChangeProductSelected
                        }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "disabled", "onChange"])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("img", {
                          src: freeProduct.imageUrl,
                          class: "product-image"
                        }, null, 8, _hoisted_7)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(freeProduct.name), 1),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(freeProduct.quantity), 1)
                      ]),
                      (freeProduct.barcode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(freeProduct.barcode), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$filters.formatNumber(freeProduct.price)) + " x " + _toDisplayString(freeProduct.quantity), 1)
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["header", "visible"])
      ]))
    : _createCommentVNode("", true)
}